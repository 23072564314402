/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
   height: 100%;
   /* box-sizing: border-box; */
   background-color: #f5f5ff;
   transition: background-color 0.3s ease-in-out;
   --removed-body-scroll-bar-size: 0px;
   /* Блокируем скролл */
  /* -webkit-overflow-scrolling: touch; */
}

body {
  margin: 0;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

:root {
  --bg-dialog: #f2f2f2;
  --bg-modal: #d0d3fd;
  --primary-color:#39608F;
}
 
.contained {
   max-width: 1024px;
   margin: 0px, auto;
}
 
.max-width-480 {
   max-width: 480px;
   margin: 0 auto;
   text-align: left;
}
 
.block {
   display: block;
}

.mat-typography {
   font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
   letter-spacing: normal;
   color: rgba(0, 0, 0, 0.87);
   line-height: normal;
}

.product-grid {
  margin: 0 auto;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
 